<template>
  <div class="page">
    <Title />
    <Navbar @setPageIndex="emitEvent" :lists="lists"/>

    <div class="page-info">
        <img :src="img_src2()" :alt="pageData.currentPage" height="70px" style="margin-top:15px;">
        <div class="page-right">
          <img :src="img_src1()" :alt="pageData.currentPage" height="70px">
        </div>
    </div>

    <div class="page-router">
      <section class="page-router-container">
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </section>
    </div>
  </div>
</template>

<script>
import Title from "../components/title/Title.vue"
import Navbar from "../components/navbar/Navbar.vue"
import pageLists from "../router/pageLists_akiya"

export default {
  name: 'Page',
  components: {
    Title,
    Navbar
  },
  data() {
    return {
      pageData: '',
      lists: pageLists
    }
  },
  methods: {
    emitEvent(data) {
      this.pageData = data
    },
    img_src1() {
      if (this.pageData === '') return null;
      else return require(`../assets/paging/${this.pageData.currentPage}.png`);
    },
    img_src2() {
      if (this.pageData === '') return null;
      else return require(`../assets/paging/page_${this.pageData.currentPage}.png`)
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  position: absolute;
  height: 100vh;
  width: 100%;
  font-family: "MS PGothic, 'Arial',YuGothic,'Yu Gothic','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3','メイリオ', Meiryo,'ＭＳ ゴシック',sans-serif;";

    
  .page-info {
    position: relative;
    height: 10%;
    text-align: center;
    margin-right: 20px;
    margin-top: 8px;

    .page-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .page-triangle-bottom {
    position: absolute;
    width: 60%;
    bottom: 0;
    left: 0;
  }

  .page-router {
    position: absolute;
    height: 80%;
    width: 100%;
    margin-bottom: 7%;
    display: flex;
    justify-content: center;
    align-items: center;

    .page-router-container {
      width: 100%;
      height: 100%;
      background-image: url("../assets/background/background_wh.png");
      background-position: center;
      background-size: contain;
      z-index: 5;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  will-change: opacity;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}
</style>
