<template>
  <div class="navbar">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          color="#132d7a"
          class="mb-3 ml-3 btn-text"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon middium class="icon">{{icons.home}}</v-icon>
          {{$t("toTop_btn_label")}}
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          {{$t("confirm_text")}}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            @click="dialog = false"
          >
            {{$t("disagree")}}
          </v-btn>

          <v-btn
            color="#132d7a"
            @click="toTop()"
          >
            <span style="color: #fff">{{$t("agree")}}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="toPage">
      <div
        v-if="showBtn.previos"
        class="next-button next previous mr-3"
        @click="previosPage()"
      >
        <span class="next-button-text">{{$t("previos_btn_label")}}</span>
      </div>

      <div
        v-if="showBtn.next"
        class="next-button next mr-3"
        @click="nextPage()"
      >
        <span class="next-button-text">{{$t("next_btn_label")}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiHomeVariantOutline } from '@mdi/js';

export default {
  name: 'navbar',
  props: {
    lists: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      icons: {
        home: mdiHomeVariantOutline
      },
      showBtn: {
        previos: false,
        next: false
      },
      slidePageLists: [],
      currentPageIndex: null,
      dialog: false
    }
  },
  watch: {
    $route() {
      this.checkShowBtn()
    }
  },
  mounted() {
    for(var i=0; i < this.lists.length; i++) {
      if(this.lists[i].meta.index != null) {
        this.slidePageLists.push(this.lists[i].meta.index)
      }
    }

    this.checkShowBtn()
  },
  methods: {
    checkShowBtn() {
      this.currentPageIndex = this.$route.meta.index

      if(this.currentPageIndex === 1) {
        this.showBtn.previos = false
        this.showBtn.next = true
      } else if(this.currentPageIndex === this.slidePageLists.length) {
        this.showBtn.previos = true
        this.showBtn.next = false
      } else {
        this.showBtn.previos = true
        this.showBtn.next = true
      }

      this.emitPageNum()
    },
    emitPageNum() {
      const currentPageData = {
        currentPage: this.currentPageIndex,
        totalPages: this.slidePageLists.length
      }

      this.$emit('setPageIndex', currentPageData)
    },
    previosPage() {
      const previosIndex = this.currentPageIndex - 1

      if(this.$route.meta.type !== 'consult') {
        this.$router.push("/" + this.$route.meta.type + "/page/" + previosIndex)
      } else {
        this.$router.push("/" + this.$route.meta.type +"/"+ previosIndex)
      }
    },
    nextPage() {
      const nextIndex = this.currentPageIndex + 1

      if(this.$route.meta.type  !== 'consult') {
        this.$router.push("/" + this.$route.meta.type + "/page/" + nextIndex)
      } else {
        this.$router.push("/" + this.$route.meta.type +"/"+ nextIndex)
      }
    },
    toTop() {
      this.dialog = false

      let type = sessionStorage.getItem('type')

      if(type) {
        sessionStorage.clear()
        this.$router.push("/" + type)
      } else {
        sessionStorage.clear()
        this.$router.push("/" + 'akiya')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.navbar {
  position: absolute;
  display: flex;
  align-items: flex-end;
  height: 100%;
  width: 100%;
  z-index: 4;

  .btn-text {
    font-size: 1.1em;
    display: flex;
    text-align: center;
  }

  .icon {
    margin-right: 5px;
  }

  .toPage {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 10px;
    display: flex;
    justify-content: flex-end;

    .next-button {
      z-index: 5;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 45px;
      width: 13%;
      border-radius: 22.5px;
      cursor: pointer;
      transition: .5s;
      

      &.next {
        color: #fafafa;
        background-color: #132d7a;
      }

      &.previous {
        color: #fafafa;
        background-color: #676767;
      }

      &.disabled {
        color: gray;
        background-color: transparent;
      }

      &:active {
        opacity: .6;
      }

      .next-button-text {
        font-size: max(16px, 1.5vw);
        font-weight: bold;
        letter-spacing: 0.2rem;
      }
    }
  }
}
</style>